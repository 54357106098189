<template>
    <div class="bucket">
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{}
    }
}
</script>

<style lang="scss">
.bucket{
    background: #2e3351;
    width:100%;
    height:100%;
    position:fixed;
    background-size:100% 100%;
}
.amap-copyright,
.amap-logo{
    display: none !important;
}
@font-face {
  font-family: 'iconfont';  /* Project id 3086955 */
  src: url('//at.alicdn.com/t/c/font_3086955_paubiwd2hj.woff2?t=1659576830357') format('woff2'),
       url('//at.alicdn.com/t/c/font_3086955_paubiwd2hj.woff?t=1659576830357') format('woff'),
       url('//at.alicdn.com/t/c/font_3086955_paubiwd2hj.ttf?t=1659576830357') format('truetype');
}
.iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
.param{
    .van-radio__label{
        color: #fff;
    }
}
.amap-marker-label{
    border: 1px solid #d6d6d6;
}
.page-list{
    .van-loading{ margin:40% 0}
}
</style>>
